import React, { Component } from 'react';
import Communicate from "../common/Communicate";
import { OCCUPATIONS } from "../common/Constants";
import { filterObject, notNullOrEmpty } from "../common/Objects";
import * as validators from "../common/Validators";
import './Register.css';
import RegisterConfirmation from "./RegisterConfirmation";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeLimit: 8,
            guildAvailable: true,
            loading: false,
            error: '',
            registered: false,
            registerData: {
                type: '0'
            },
            possibleLicenses: [],
            registerDataErrors: {
                login: 'Pole wymagane',
                name: 'Pole wymagane',
                lastname: 'Pole wymagane',
                email: 'Pole wymagane',
                license: 'Pole wymagane',
                occupation: 'Pole wymagane',
                guild: 'Pole wymagane'
            }
        };
    }

    changeAccountType(e) {
        e.persist();
        this.setState({
            typeLimit: e.target.value === '0' ? 8 : 10,
            guildAvailable: e.target.value === '0'
        }, () => {
            this.setRegisterData(e);
            if (e.target.value === '1') {
                this.setRegisterDataByName('guild', 'Inna');
            }
        });
    }

    setRegisterData(e) {
        const { name, value } = e.target;
        this.setRegisterDataByName(name, value);
    }

    setRegisterDataByName(name, value) {
        this.setState(prevState => {
            let registerData = Object.assign({}, prevState.registerData);
            registerData[name] = value;
            return { registerData };
        }, () => {
            this.setState(prevState => {
                let registerDataErrors = Object.assign({}, prevState.registerDataErrors);
                registerDataErrors[name] = this.validateField(name, value);
                return { registerDataErrors };
            })
        });
    }

    setAccount(e) {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcOajoaAAAAANbpMI7uHd-j_G2uf6EwZVfn0JeV', { action: 'register' })
                .then(token => {
                    this.setState({
                        loading: true
                    }, () => {
                        fetch(`${process.env.REACT_APP_API_URL}/public/auth/register`, {
                            method: 'POST',
                            cache: 'no-cache',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(
                                Object.assign(
                                    filterObject(this.state.registerData, notNullOrEmpty),
                                    { 'token': token }
                                ))
                        })
                            .then(response => response.json())
                            .then(response => {
                                if (response.status === 200) {
                                    this.setState({
                                        loading: false,
                                        registered: true,
                                        error: ''
                                    });
                                } else {
                                    throw response;
                                }
                            })
                            .catch(response => this.setState({
                                error: this.getErrorCommunicate(response),
                                loading: false
                            }))
                    });
                });
        });
    }

    getErrorCommunicate(response) {
        switch (response.status) {
            case 400:
                return 'Wysłany formularz zawierał nieprawidłowe dane, prosimy o ponowne sprawdzenie.';
            case 409:
                return `Wybrany ${response.errors.join(', ')} jest już zajęty lub nieprawidłowy, prosimy o wybranie innego lub o kontakt.`;
            default:
                return 'Wystąpił błąd, prosimy spróbować ponownie. W razie dalszych problemów prosimy o kontakt.';
        }
    }

    validateField(name, value) {
        return this.getValidators(name, value).find(v => v);
    }

    getValidators(name, value) {
        switch (name) {
            case 'login':
                return [
                    validators.require(value),
                    validators.alnum(value),
                    validators.size(value, 6, 30)];
            case 'name':
                return [
                    validators.require(value),
                    validators.alnumPolish(value),
                    validators.size(value, 2, 20)
                ];
            case 'lastname':
                return [
                    validators.require(value),
                    validators.alnumPolish(value),
                    validators.size(value, 2, 30)
                ];
            case 'email':
                return [
                    validators.require(value),
                    validators.email(value)
                ];
            case 'type':
                return [
                    validators.require(value),
                    validators.length(value, 1),
                    validators.numbers(value)
                ]
            case 'license':
                return [
                    validators.require(value),
                    validators.length(value, this.state.typeLimit),
                    validators.numbers(value)
                ];
            case 'occupation':
                return [
                    validators.require(value)
                ];
            case 'guild':
                return [
                    validators.require(value)
                ];
            default:
                return [''];
        }
    }

    isInvalidInput() {
        return Object.values(this.state.registerDataErrors)
            .find(a => a) !== undefined;
    }

    render() {
        return this.state.registered ?
            <RegisterConfirmation /> :
            <div>
                {this.state.error ? <Communicate communicate={this.state.error} error={true} /> : ''}
                <form>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="type">Typ konta</label>
                        <select id="type"
                            name="type"
                            disabled={this.state.loading}
                            onChange={e => this.changeAccountType(e)}>
                            <option value="0">Farmaceuta</option>
                            <option value="1">Technik farmaceutyczny</option>
                        </select>
                        <span>{this.state.registerDataErrors.type}</span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="login">Login</label>
                        <input type="text"
                            name="login"
                            id="login"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="login" />
                        <span>{this.state.registerDataErrors.login}</span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="name">Imię</label>
                        <input type="text"
                            name="name"
                            id="name"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="imię" />
                        <span>{this.state.registerDataErrors.name}</span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="lastname">Nazwisko</label>
                        <input type="text"
                            name="lastname"
                            id="lastname"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="nazwisko" />
                        <span>{this.state.registerDataErrors.lastname}</span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="email">Adres email</label>
                        <input type="text"
                            name="email"
                            id="email"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="email" />
                        <span>{this.state.registerDataErrors.email}</span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="license">
                        {this.state.registerData.type === '0' ? 'Numer PWZ' : 'Identyfikator'}
                        </label>
                        <input type="text"
                            name="license"
                            id="license"
                            list="licenseList"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder={this.state.registerData.type === '0' ? 'PWZ' : 'Indywidualny numer'}
                            autoComplete="license" />
                        <datalist id="licenseList">
                            {this.state.possibleLicenses.map((possibleLicense, index) =>
                                <option key={index}>{possibleLicense}</option>)}
                        </datalist>
                        <span>{this.state.registerDataErrors.license}</span>
                        <span>
                            {this.state.registerData.type === '0' ?
                            <a target="_blank" href="https://crf.ezdrowie.gov.pl/Search/">
                                Sprawdź swój numer PWZ
                            </a>
                            :
                            <a target="_blank" href="https://rizm.ezdrowie.gov.pl/">
                                Sprawdź swój numer
                            </a>}
                        </span>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="occupation">Miejsce pracy</label>
                        <select id="occupation"
                            name="occupation"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}>
                            <option value="">-- wybierz --</option>
                            {OCCUPATIONS.map((occupation, index) =>
                                <option key={index} value={index}>{occupation}</option>)}
                        </select>
                        <span>{this.state.registerDataErrors.occupation}</span>
                    </div>
                    {this.state.guildAvailable ?
                        <div className="form-row">
                            <label className="hidden-sm" htmlFor="guild">Izba</label>
                            <select id="guild"
                                name="guild"
                                disabled={this.state.loading}
                                onChange={e => this.setRegisterData(e)}>
                                <option value="">-- wybierz --</option>
                                <option value="OIA w Białymstoku">OIA w Białymstoku</option>
                                <option value="OIA w Bielsku-Białej">OIA w Bielsku-Białej</option>
                                <option value="OIA w Bydgoszczy">OIA w Bydgoszczy</option>
                                <option value="OIA w Częstochowie">OIA w Częstochowie</option>
                                <option value="OIA w Gdańsku">OIA w Gdańsku</option>
                                <option value="OIA w Kaliszu">OIA w Kaliszu</option>
                                <option value="OIA w Katowicach">OIA w Katowicach</option>
                                <option value="OIA w Kielcach">OIA w Kielcach</option>
                                <option value="OIA w Koszalinie">OIA w Koszalinie</option>
                                <option value="OIA w Krakowie">OIA w Krakowie</option>
                                <option value="OIA w Lublinie">OIA w Lublinie</option>
                                <option value="OIA w Łodzi">OIA w Łodzi</option>
                                <option value="OIA w Olsztynie">OIA w Olsztynie</option>
                                <option value="OIA w Opolu">OIA w Opolu</option>
                                <option value="OIA w Poznaniu">OIA w Poznaniu</option>
                                <option value="OIA w Rzeszowie">OIA w Rzeszowie</option>
                                <option value="OIA w Szczecinie">OIA w Szczecinie</option>
                                <option value="OIA w Warszawie">OIA w Warszawie</option>
                                <option value="OIA we Wrocławiu">OIA we Wrocławiu</option>
                                <option value="OIA w Zielonej Górze">OIA w Zielonej Górze</option>
                            </select>
                            <span>{this.state.registerDataErrors.guild}</span>
                        </div> : ''}
                    <div className="form-row">
                        <p className="form-legend">Poniższe pola są nieobowiązkowe</p>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="address">Adres</label>
                        <input type="text"
                            id="address"
                            name="address"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="adres" />
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="postal">Kod pocztowy</label>
                        <input type="text"
                            id="postal"
                            name="postal"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="kod pocztowy"
                            size="8" />
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="city">Miasto</label>
                        <input type="text"
                            id="city"
                            name="city"
                            disabled={this.state.loading}
                            onChange={e => this.setRegisterData(e)}
                            placeholder="miasto" />
                    </div>
                    {this.state.loading ?
                        <div className="spinner" /> :
                        <div className="form-row">
                            <label className="hidden-sm" />
                            <button
                                disabled={this.isInvalidInput()}
                                onClick={e => this.setAccount(e)}
                                className="primary">Załóż nowe konto
                            </button>
                        </div>}
                </form>
                <br />
                <h2>Przeczytaj uważnie</h2>
                <p>Przesyłając nam ten formularz wyrażasz zgodę na gromadzenie, przetwarzanie i wykorzystywanie
                    Twoich danych na potrzeby działania systemu e-dukacja&reg;, teraz i w przyszłości, zgodnie z polskim
                    prawem, w szczególności Ustawą o ochronie danych osobowych. Masz prawo wglądu do Twoich
                    danych oraz ich poprawienia lub usunięcia.</p>
            </div>
    }
}

export default Register;
