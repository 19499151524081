import React, {Component} from 'react';
import './Enrollments.css';
import {Link} from "react-router-dom";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Enrollments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            linked: this.props.linked,
            courses: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/courses/${this.state.type}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({courses: data}))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return (
            <div className="courses-list">
                <h2>{this.state.title}</h2>
                {this.state.courses !== undefined ?
                    this.state.courses.length > 0 ?
                        <ul className="striped">
                            {this.state.courses.map((course, index) =>
                                <li key={index}>
                                    {this.state.linked && course.status == '2' ?
                                        <Link
                                            to={`/course/${course['idco']}`}>{course['name']}
                                        </Link> :
                                        <span>{course['name']}</span>} (
                                    {course['result'] ?
                                        <span><b>Zakończony: </b>{course['finished']}, <b>Wynik: </b>{course['result']}%, </span> : ''}
                                    <b>Typ: </b>{course['ptype']}, <b>Punkty: </b>{course['points']})
                                </li>
                            )}
                        </ul> :
                        <p>Brak wpisów</p>
                    : <div className="spinner"/>}
            </div>
        );
    }
}

export default Enrollments;